body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.table-wrapper {
  background-color: white;
  padding: 16px 24px 32px;
  margin-bottom: 16px;
}

.ant-table {
  overflow-x: auto;
}

.ant-form-item {
  width: 100%;
}

@media (min-width: 992px) {
  .ant-form-item {
    width: 70%;
  }

  .ant-form-item-row {
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    white-space: nowrap;
  }
}

@media (max-width: 991px) {
  .ant-form-item-label {
    text-align: start !important;
  }
}

.list-page {
  .header {
    background-color: white;
    padding: 16px 24px;
    margin-bottom: 16px;
  }

  .header h4 {
    margin: 0;
  }

  .table-content {
    background-color: white;
    padding: 16px 24px 32px;
  }
}
